import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";



const useStyles = makeStyles({
    root: {
      padding: '8px',
      height: '100vh',
    },
    paper: {
      width: '100%',
      height: '100vh',
      backgroundColor: 'grey',
      maxHeight: '100vh',
      overflow: 'auto',
    },
    card: {
      backgroundColor: 'blue',
    },
  });


export default function ProviderList({publicWebsite, closeProviders, specialty}) {
  const classes = useStyles();
  let cards = null;
  if( publicWebsite){
    return <></>
  }
  if (
    closeProviders !== undefined &&
    closeProviders !== null &&
    closeProviders.length > 0
  ) {
    cards = closeProviders.map((provider) => {

      if( specialty !== undefined && specialty !== null){
        // we have a filter request
        if(provider !== null && provider.provSpecCode !== specialty.code){
          return;
        }
      }
      if( provider.distance !== undefined && provider.distance !== null){
        let distance_display = (Math.round(provider.distance * 100) / 100).toFixed(2);
        provider.distance = distance_display;
      }
      return (

        <Card variant="outlined" sx={{ minWidth: 275 }}>
          <CardContent>
          <Typography variant="body" component="div">
              {provider.provFirstName} {provider.provLastName}
            </Typography>
          <Typography variant="body" component="div">
              {provider.provNpi}
            </Typography>
            <Typography variant="body" component="div">
              {provider.servDirectAddr}
            </Typography>
            <Typography variant="body" component="div">
              {provider.provSpecText}
            </Typography>
            <Typography variant="body" component="div">
              {provider.orgName}
            </Typography>
            <Typography variant="body" component="div">
              {provider.distance} miles
            </Typography>
          </CardContent>
        </Card>
      );
    });
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper}>{cards}</Paper>
    </React.Fragment>
  );
}
