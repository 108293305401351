import { useState} from "react";
import TextField from '@mui/material/TextField';

export default function NpiSearchText({ setSearchText, setSearchResults, setAddressSearchText }) {
    const [input, setInput] = useState();
    
    function handleSubmit(e) {
      e.preventDefault();
      //console.log("handleSubmit, input is: " + input);
      setSearchText(input);
      setSearchResults(null); 
      setAddressSearchText(null); 
      //console.log("handleSubmit, clearing searchResult");
    }
  
    function handleChange(e) {
      //console.log("handleChange, setting input to: " + e.target.value);
      setInput(e.target.value);
      setSearchText(null);
      setAddressSearchText(null); 
      
    }
  
    return (
      <div>
        <form onSubmit={handleSubmit}>
        
          <TextField
            variant="outlined"
            onChange={handleChange}
            placeholder="Enter direct address or npi"
            id="searchText"
            label="Direct Address/Npi"
          />
        </form>
      </div>
    );
  }