import React from 'react';
import Filter from './components/Filter';
import Range from './components/Range';
import ProviderSearch from './components/ProviderSearch';
import MLMap from './components/MLMap';
import './App.css';
import { useState } from 'react';
import Container from '@mui/material/Container';
import { Typography, CssBaseline, AppBar, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ProviderList from './components/ProviderList';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import SpecialtySelect from './components/SpecialtySelect';
import ServDescSelect from './components/ServDescSelect';
import NpiSearchText from './components/NpiSearchText';
import AddressSearch from './components/AddressSearch';
import Search from './components/Search';
import logo from './DirectTrustR_RGB_Logo_horiz.png';


/*
https://docs.google.com/document/d/1IbqEPcLvr_gOh4vEtsVF9zqO10jeGJfUStOVLYAU1K0/edit?tab=t.0
Success! Created provider-search-map at /Users/eric/Documents/ProviderSearch/react/provider-search-map
Inside that directory, you can run several commands:

  npm start
    Starts the development server.

  npm run build
    Bundles the app into static files for production.
    using Express Server
    https://itnext.io/node-express-letsencrypt-generate-a-free-ssl-certificate-and-run-an-https-server-in-5-minutes-a730fbe528ca

  npm test
    Starts the test runner.

  npm run eject
    Removes this tool and copies build dependencies, configuration files
    and scripts into the app directory. If you do this, you can’t go back!

We suggest that you begin by typing:

  cd provider-search-map
  npm start

Happy hacking!


https://mui.com/material-ui/all-components/


*/

function setPosition(position) {
  console.log("setPosition callback: " + position.coords.latitude + " " + position.coords.longitude);
}
function error(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

function App() {

  const [publicWebsite, setPublicWebsite] = useState(true);
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [servDirectAddr, setServDirectAddr] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [osmSearchResults, setOsmSearchResults] = useState(null);
  const [range, setRange] = useState("2");
  const [specialty, setSpecialty] = React.useState(null);
  const [servDesc, setServDesc] = React.useState(null);
  const [closeProviders, setCloseProviders] = React.useState(null);
  const [closeProviderSpecialties, setCloseProviderSpecialties] = React.useState(null);
  const [searchText, setSearchText] = useState("");
  const [addressSearchText, setAddressSearchText] = useState("");


/* geolocation debug start */
if ("geolocation" in navigator){
  if( navigator.geolocation !== undefined){
    console.log("calling navigator.geolocation.getCurrentPosition ");
    navigator.geolocation.getCurrentPosition(setPosition, error);
  }
}else {
  console.log("geolocation is NOT available ");
}
/* geolocation debug end */
  if ("geolocation" in navigator && lng == null && lat == null) {
    /* geolocation is available */

    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
      console.log("geolocation is available " + position.coords.latitude + " " + position.coords.longitude);
    });
    
  } else {
    if (lng == null && lat == null) {
      /* geolocation IS NOT available */
      console.log("geolocation IS NOT available");
    }
  }

  let searchResultsObject = null;
  if (searchResults) {
    searchResultsObject = JSON.parse(searchResults.body);

    if (typeof (searchResultsObject.latitude) != "undefined" && searchResultsObject.latitude != null && typeof (searchResultsObject.longitude) != "undefined" && searchResultsObject.longitude != null) {

      searchResultsObject.longitude = parseFloat(searchResultsObject.longitude) + 90;
      if (lng !== searchResultsObject.longitude || lat !== searchResultsObject.latitude) {
        console.log("App setting new center to " + searchResultsObject.latitude + " " + searchResultsObject.longitude + " for " + searchResultsObject.directAddress);

        setLng(searchResultsObject.longitude);
        setLat(searchResultsObject.latitude);
        setServDirectAddr(searchResultsObject.directAddress);
      }
    } else {
      console.log("searchResultsObject lat long is missing...");
    }
  }

  if (osmSearchResults) {
    console.log("osmSearchResults " + osmSearchResults);
    if( osmSearchResults.features.length > 0){
      console.log(osmSearchResults.features.length + " features found.");
      osmSearchResults.features.forEach(feature =>{
           console.log(feature.properties.display_name);
      })
      let coordinates = osmSearchResults.features[0].geometry;
      let osmLat = coordinates.coordinates[1];
      let osmLng = coordinates.coordinates[0];
      if (typeof (osmLat) != "undefined" && osmLat != null && typeof (osmLng) != "undefined" && osmLng != null) {
        if (lng !== osmLng || lat !== osmLat) {
          console.log("App setting new center to " + osmLat + " " + osmLng);
          setLng(osmLng);
          setLat(osmLat);
        }
      }
    }
  }

  let xs = 8
  if( publicWebsite)
  {
    
    xs = 12
  } 
  
  return (
  <>
    <CssBaseline/>
    <AppBar position='relative'>
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
          Provider Search
        </Typography>
      </Toolbar>
    </AppBar>
    <Container maxWidth="lg" sx={{mt:4, mb:4}}>
      

      <img src={logo} width="20%" height="20%"/>

      <Paper elevation={3}>
      
      <Box sx={{ flexGrow: 1, border: '1px' }} >
      <Grid container spacing={2}>
        <Grid item xs={4} spacing={2}>
        <Box sx={{ margin: 2}} >
          <Range range={range} setRange={setRange}/>
        </Box>
        <Box sx={{ margin: 2}} >
          <NpiSearchText setSearchText={setSearchText} setSearchResults={setSearchResults} setAddressSearchText={setAddressSearchText}/>
        </Box>
        </Grid>
        <Grid item xs={4}>
        <Box sx={{ margin: 2}} >
          <SpecialtySelect setSpecialty={setSpecialty} closeProviderSpecialties={closeProviderSpecialties}/>
        </Box>
        <Box sx={{ margin: 2}} >
          <AddressSearch setAddressSearchText={setAddressSearchText} setSearchResults={setSearchResults} setSearchText={setSearchText}/>
        </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ margin: 2}} >
            <ServDescSelect setServDesc={setServDesc}/>
          </Box>
       </Grid>
        <Grid item xs={12}>
        <Search searchText={searchText} addressSearchText={addressSearchText} setSearchResults={setSearchResults} setOsmSearchResults={setOsmSearchResults}/>
        </Grid>          
      </Grid>
    </Box>
    </Paper>
  
    <Grid container direction="row">

      <Grid item xs={4}>
        <ProviderList publicWebsite={publicWebsite} closeProviders={closeProviders} specialty={specialty}/>
      </Grid>  

      <Grid item direction="row" xs={xs}>
          <Paper elevation={3}>
            <Box p={2}>
              <MLMap lat={lat} lng={lng} servDirectAddr={servDirectAddr} searchResults={searchResults} osmSearchResults={osmSearchResults} range={range} specialty={specialty} servDesc={servDesc} closeProviders={closeProviders} setCloseProviders={setCloseProviders} setCloseProviderSpecialties={setCloseProviderSpecialties}/>
            </Box>
          </Paper>
      </Grid>
    </Grid>   
    <Typography variant="h6">Evergreen text goes here</Typography> 
    </Container>
    </>
  );
}

export default App;