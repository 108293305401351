import { useState, useEffect } from "react";
import SearchResults from './SearchResults'; 
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export function CustomBackdrop (props) {
  return (
      <Backdrop sx={{zIndex: theme => theme.zIndex.drawer + 1, color: "#fff"}}
                open={props.open}>
          <CircularProgress color={"inherit"}/>
      </Backdrop>
  )
}

export default function Search({searchText, addressSearchText, setOsmSearchResults, setSearchResults}) {
    const [apiSearchResults, setApiSearchResults] = useState(null); 
    const [searching, setSearching] = useState(false);
    //console.log("SearchResult load...");
    //console.log("searchText: " + props.searchText);
  
    //let setSearchResults = props.setSearchResults;
    //let setOsmSearchResults = props.setOsmSearchResults;
    useEffect(() => {
      console.log("useEffect: searchText: " + searchText);
      
      let bodyObj ={};
      let isNpi = false;
      let isDirectAddress = false;
      let isStreetAddress = false;
      if( searchText != null && searchText.match(/[^\w]|_/) == null){
        // Numeric, it's an npi
        console.log("useEffect: " + searchText + " is an NPI");
        bodyObj.npi = searchText;
        isNpi = true;
      } else {
        // It's a direct address
          console.log("useEffect: " + searchText + " is a direct address");
          bodyObj.directAddress = searchText;
          isDirectAddress = true;
      }
      if( addressSearchText != null){
        console.log("useEffect: " + addressSearchText + " is a street address");
        bodyObj.streetAddress = addressSearchText;
        isStreetAddress = true;
      }

      console.log("bodyObj=" + JSON.stringify(bodyObj));

      if( searchText && (isDirectAddress || isNpi)) {
        setSearching(true);
        fetch("https://w4c4ylzii5.execute-api.us-east-2.amazonaws.com/v1/provider-search", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "IZ44pNgw2i3maM97akCrG61vPgAwwuWh72gcJewv"
            },
            body: JSON.stringify(
              bodyObj
            )
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setSearchResults(data);  
              setApiSearchResults(data);
              console.log("useEffect: got data apiSearchResults: " + data);
              setSearching(false);

            })
      }
      if( addressSearchText && isStreetAddress) {
        setSearching(true);
        let query = "https://nominatim.openstreetmap.org/search?q=" + bodyObj.streetAddress + "&format=geojson";
        query = encodeURI(query);
        console.log("query: " + query);
        
        fetch(query, {
            method: "GET"
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setOsmSearchResults(data);  
              setSearching(false);
            })
              
      }
      

}, [searchText,addressSearchText,setSearchResults]);
  
  
    
    return (
      <div>
        <SearchResults searchText={searchText} searchResults={apiSearchResults} />
        <CustomBackdrop open={searching}/>
      </div>
    );
  }