import { useState} from "react";
import TextField from '@mui/material/TextField';

export default function AddressSearch({ setAddressSearchText, setSearchResults, setSearchText }) {
    const [input, setInput] = useState();
    
    function handleSubmit(e) {
      e.preventDefault();
      //console.log("handleSubmit, input is: " + input);
      setAddressSearchText(input);
      setSearchResults(null);  
      setSearchText(null);
      //console.log("handleSubmit, clearing searchResult");
    }
  
    function handleChange(e) {
      //console.log("handleChange, setting input to: " + e.target.value);
      setInput(e.target.value);
      setAddressSearchText(null);
      setSearchText(null);

    }
  
    return (
      <div>
        <form onSubmit={handleSubmit}>
        
          <TextField
            variant="outlined"
            onChange={handleChange}
            placeholder="Enter a street address"
            id="searchText"
            label="Street Address"
          />
        </form>
      </div>
    );
  }