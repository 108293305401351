
import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";


export default function Range({range, setRange}) {

    const handleChange = (event) => {
      setRange(event.target.value);
    };
  
  
    return (
        <>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Range</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={range}
              label="Range"
              onChange={handleChange}
              sx={{ width: 200 }}
            >
              <MenuItem value={1}>1 Mile</MenuItem>
              <MenuItem value={2}>2 Miles</MenuItem>
              <MenuItem value={5}>5 Miles</MenuItem>
              <MenuItem value={10}>10 Miles</MenuItem>
            </Select>
          </FormControl>
        </>
        );
    }